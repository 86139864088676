// Popup.js
import React from 'react';
import './Popup.css'; // Create a CSS file for styling

const Popup = ({ message, onClose }) => {
  React.useEffect(() => {
    const timer = setTimeout(onClose, 5000); // Auto close after 3 seconds
    return () => clearTimeout(timer);
  }, [onClose]);
  return (
    <div className="popup">
      <p>{message}</p>
    </div>
  );
};

export default Popup;
