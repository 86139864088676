import React from 'react';
import './PrivacyPolicy.css'; 
import {config} from '../../config';

const PrivacyPolicy = ({ onBack }) => {


  React.useEffect(() => {
       
    document.documentElement.style.setProperty('--main-color', config.color);
}, [config.color]);
  return (
    <div className="privacy-policy-container">
      <div className="privacytopdiv">
        <button className="back-button" onClick={onBack}>
          <img src={config.Backbutton} alt="Back" />
          <span>Privacy Policy</span>
        </button>
      </div>

      <div id="policy-content">
      <h1>{config.privacyTitle}</h1>
      <p>Effective Date: {config.privacyEffectiveDate}</p>
      {config.privacyPoints.map((section, index) => (
        <div key={index}>
          <h2>{section.heading}</h2>
          {section.subsections ? (
            section.subsections.map((sub, subIndex) => (
              <div key={subIndex}>
                <h3>{sub.heading}</h3>
                {sub.content.map((text, textIndex) => (
                  <p key={textIndex}>{text}</p>
                ))}
              </div>
            ))
          ) : (
            <div>
              {section.content.map((text, textIndex) => (
                <p key={textIndex}>{text}</p>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
    </div>
  );
};

export default PrivacyPolicy;
