import React from 'react';
import './FAQ.css'; 
import { config } from '../../config';
const FAQ = ({ bookimg }) => {
  return (
    <div className='FAQ'>
      <div className='FAQ_title_div'>
        <span className='FAQ_title'>{config.FAQtitle}</span>
      </div>
      <div className='FAQ_content'>
        <div className='FAQ_points'>
        {config.points.map((point, index) => (
            <div className='FAQ_points' key={index}>
              <div> {point.step}</div>
              <ul>
                {point.details.map((detail, i) => (
                  <li key={i}>{detail}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <img src={config.book} alt="Book" className='FAQ_image' />
      </div>
    </div>
  );
};

export default FAQ;
