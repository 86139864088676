import React, { useState, useEffect } from 'react';
import copyicon from '../../assets/copy_btn.svg';
import shareicon from '../../assets/share_btn.svg';
import './HistoryPreview.css';
import {config} from '../../config';
import { analytics } from './firebase'; 
import { logEvent } from 'firebase/analytics';

const HistoryPreview = ({ result, onBack, GeneratedResult }) => {
  const [displayedText, setDisplayedText] = useState('');
  React.useEffect(() => {
       
    document.documentElement.style.setProperty('--main-color', config.color);
}, [config.color]);

  useEffect(() => {
    if (result) {
      // Directly set the displayed text
      setDisplayedText(result);
    }
  }, [result]);

  const handleCopy = () => {
    navigator.clipboard.writeText(displayedText);
    alert('Text copied to clipboard!');
    logEvent(analytics, 'academic_action', {
      action: 'copy_academic_content',          // Action name
      button_name: 'copy_button',               // Button name (optional)    // Timestamp of the action
      source: 'history_preview',               // Source where the event originated (optional)
    });
  };

  const handleShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: "Generated Academics",
          text: displayedText,
        });
        logEvent(analytics, 'academic_action', {
          action: 'share_academic_content',         // Action name
          button_name: 'share_button',              // Button name (optional)
          page_name: 'history_preview_page',        // Page name    // Timestamp
          source: 'history_preview',               // Source
        });
      }
    } catch (error) {
      console.error('Error while sharing:', error);
      alert('Sharing failed. Please try again.');
    }
  };

  return (
    <div className="generate-page-container">
      <div className="generate-topdiv">
        <div className='backbtntext'>
          <button className="back-button" onClick={onBack}>
            <img src={config.Backbutton} alt="Back" />
          </button>
          <span>Your Academic </span> {/* Changed title to "Your Document" */}
        </div>
        <div className="button-container">
          <img src={copyicon} alt="Copy" onClick={handleCopy} />
          <img src={shareicon} alt="Share" onClick={handleShare} />
        </div>
      </div>
      <pre
        id="generate-document-content"
        style={{
          whiteSpace: 'pre-wrap',
          fontFamily: 'Noto Sans',
          padding: '20px',
          borderRadius: '5px'
        }}
      >
        {displayedText}
      </pre>
    </div>
  );
};

export default HistoryPreview;
