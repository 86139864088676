import React from 'react';
import writeLogo from '../../assets/write logo.svg'; 
import writeLogoActive from '../../assets/write logo - Copy.svg';
import historyLogo from '../../assets/history_logo.svg'; 
import historyLogoActive from '../../assets/history_logo - Copy.svg'; 
import './TabButtons.css';
import { analytics } from './firebase'; 
import { logEvent } from 'firebase/analytics';
import {config} from '../../config';

const TabButtons = ({ activeTab, onTabChange }) => {
    const buttonColor = config.color;

    const handleTabChange = (tab) => {
        logEvent(analytics, 'tab_change_academic', {
            action: 'change_tab',            // Action name
            tab_name: tab,                   // Name of the tab being clicked
            page_name: 'main_page',          // Name of the page or section// Timestamp of the event
          });
      
        onTabChange(tab);
       
    };

    return (
        <div className='tab-container'>
            <div className="tabs_btn">
                <button
                    className={`tabs-btn ${activeTab === 'write' ? 'active' : ''}`}
                    onClick={() => handleTabChange('write')}
                    style={{
                        color: activeTab === 'write' ? buttonColor : '#9AA4B8',
                        borderBottom: activeTab === 'write' ? `2px solid ${buttonColor}` : 'none'
                    }}
                >
                    <img 
                        src={activeTab === 'write' ? writeLogoActive : writeLogo} 
                        alt="Write" 
                        className="tabs-icon" 
                    />
                    {config.Writetab}
                </button>
                <button
                    className={`tabs-btn ${activeTab === 'history' ? 'active' : ''}`}
                    onClick={() => handleTabChange('history')}
                    style={{
                        color: activeTab === 'history' ? buttonColor : '#9AA4B8',
                        borderBottom: activeTab === 'history' ? `2px solid ${buttonColor}` : 'none'
                    }}
                >
                    <img 
                        src={activeTab === 'history' ? historyLogoActive : historyLogo} 
                        alt="History" 
                        className="tabs-icon" 
                    />
                    History
                </button>
            </div>
        </div>
    );
};

export default TabButtons;
