import React, { useState, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { config } from '../../config';

const GoogleAuth = ({ onLoginSuccess, onLoginFailure, id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('userProfile');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const login = useGoogleLogin({
        onSuccess: (credentialResponse) => {
            setIsLoading(false);
            handleLoginSuccess(credentialResponse);
        },
        onError: (error) => {
            setIsLoading(false);
            handleLoginFailure(error);
        },
        scope: 'profile email',
    });

    const handleButtonClick = () => {
        setIsLoading(true);
        login();
    };

    const handleLoginSuccess = (response) => {
        if (response.access_token) {
            fetchUserProfile(response.access_token);
        }
    };

    const handleLoginFailure = (error) => {
        toast.error('Login Failed!', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
        });
    };

    const fetchUserProfile = async (token) => {
        try {
            const response = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Error fetching user profile');
            }

            const userProfile = await response.json();
            const { email, name, picture, verified_email, id } = userProfile;

            // Check for click count in local storage
            const clickCountData = localStorage.getItem('clickCount');
            if (clickCountData) {
                const { count, lastClickDate } = JSON.parse(clickCountData);
                const today = new Date().toISOString().split('T')[0]; // Get today's date in YYYY-MM-DD format
                const lastClickDay = new Date(lastClickDate).toISOString().split('T')[0];

                // If last click date is today, send the click count to the backend
                if (today === lastClickDay) {
                    await saveUserToDatabase({ email, name, picture, verified_email, id, clickCount: count });
                } else {
                    await saveUserToDatabase({ email, name, picture, verified_email, id });
                }
            } else {
                await saveUserToDatabase({ email, name, picture, verified_email, id });
            }

        } catch (error) {
            console.error(error); // Log any errors
        }
    };

    const saveUserToDatabase = async (userProfile) => {
        try {
            const response = await fetch(`${config.baseURL}/save_user.php`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userProfile), // Send all userProfile details including clickCount if available
            });

            if (!response.ok) {
                throw new Error('Failed to save user to database');
            }

            const { id } = await response.json(); // Assuming the backend returns { id: newUserId }
            const userData = { email: userProfile.email, name: userProfile.name, id };
            localStorage.setItem('userProfile', JSON.stringify(userData)); 
            setUser(userData); // Update state with user data
            onLoginSuccess(userData); // Call onLoginSuccess with user data
           
        } catch (error) {
            console.error(error); // Log any errors
        }
    };

    const handleLogout = () => {
        localStorage.removeItem('userProfile');
        setUser(null);
        window.location.reload();
    };

    return (
        <>
            {user ? (
                <div>
                    
                    <button onClick={handleLogout} id={id}>Logout</button>
                </div>
            ) : (
                <button onClick={handleButtonClick} id={id} disabled={isLoading}>
                    {isLoading ? '...' : 'Login'}
                </button>
            )}
        </>
    );
};

export default GoogleAuth;
